<template>
  <div class="step-content">
    <div
      v-for="n in 3"
      :key="n"
      class="step"
      :class="{ selected: selected === n }"
    ></div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    step: Number
  },
  computed: {
    selected: {
      get () {
        return this.step
      },
      set (val) {
        this.$emit('update', val)
      }
    }
  }
})
</script>

<style scoped>
.step-content {
  margin: 1rem auto;
  margin-bottom: 2rem;
  width: 300px;
  max-width: 96%;
  display: flex;
  justify-content: space-between;
}

.step {
  background: #d8d8d8;
  width: calc(100% / 3 - 0.5rem);
  height: 0.4rem;
  border-radius: 1rem;
  transition: 0.1s linear;
}

.step.selected {
  background: #14a949;
}
</style>
